/* WebsiteDesign.module.css */

.web-service {
    text-align: center;
    margin-bottom: 20px;
    color: white;
    font-family: 'Cutive Mono', monospace;
    transition: all 0.3s ease; /* Add transition for smooth animations */
}

.web-service img {
    width: 100px;
    height: 100px;
    transition: transform 0.3s ease; /* Add transition for image scaling */
    
}

.web-service h2 {
    margin-top: 10px;
    transition: color 0.3s ease; /* Add transition for text color */
}

.web-service p {
    margin-bottom: 10px;
    transition: opacity 0.3s ease; /* Add transition for opacity */
}

.web-service ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.web-service ul li {
    margin-bottom: 5px;
}

.goBackButton {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    text-align: center;
    background-color: #cd5b12;
    color: white;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.goBackButton:hover {
    background-color: darkorange;
}

/* Additional animations */
.web-service:hover {
    transform: translateY(-5px); /* Add translateY animation on hover */
}

.web-service img:hover {
    transform: scale(1.1); /* Add scaling animation on image hover */
}

.web-service h2:hover {
    color: orange; /* Change text color on hover */
}

.web-service p:hover {
    opacity: 0.8; /* Reduce opacity on hover */
}
