/* CheckoutModal.css */

.checkout-modal {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    width: 300px;
  }
  
  .checkout-modal h2 {
    margin-top: 0;
  }
  
  .checkout-modal ul {
    padding: 0;
    list-style-type: none;
  }
  
  .checkout-modal li {
    margin-bottom: 5px;
  }
  
  .item-name {
    font-weight: bold;
    margin-right: 10px;
  }
  
  .total-price {
    font-weight: bold;
    margin-top: 10px;
  }
  
  .confirm-purchase-button {
    background-color: #5cb85c;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .confirm-purchase-button:hover {
    background-color: #4cae4c;
  }
  