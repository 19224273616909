/* Responsive Design Styles */
/* Default styles for all screen sizes */

/* Small smartphones */
@media (max-width: 320px) {
  /* Component-specific styles for screens up to 320px width */
  .hero {
    height: 100vh;
    min-height: 20vh;
    padding: 2%;
  }
}

/* Larger smartphones */
@media (min-width: 321px) and (max-width: 480px) {
  /* Component-specific styles for screens between 321px and 480px width */
  .hero {
    height: 100vh;
    min-height: 20vh;
    padding: 2%;
  }
}

/* Tablets */
@media (min-width: 481px) and (max-width: 768px) {
  /* Component-specific styles for screens between 481px and 768px width */
  .hero {
    height: 100vh;
    min-height: 30vh;
    padding: 3%;
  }
}

/* Small laptops, tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  /* Component-specific styles for screens between 769px and 1024px width */
  .hero {
    height: 100vh;
    min-height: 30vh;
    padding: 3%;
  }
}

/* Larger laptops */
@media (min-width: 1025px) and (max-width: 1200px) {
  /* Component-specific styles for screens between 1025px and 1200px width */
  .hero {
    height: 100vh;
    min-height: 30vh;
    padding: 3%;
  }
}

/* Larger desktops */
@media (min-width: 1201px) and (max-width: 1440px) {
  /* Component-specific styles for screens between 1201px and 1440px width */
  .hero {
    height: 100vh;
    min-height: 30vh;
    padding: 3%;
  }
}

/* Extra-large desktops */
@media (min-width: 1441px) {
  /* Component-specific styles for screens larger than 1440px width */
  .hero {
    height: 100vh;
    min-height: 30vh;
    padding: 3%;
  }
}

/* CENTER ID Styles */
/* Make the .home-page rule more specific */
body.home-page,
html.home-page {
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

/* Add styles for hero section */
.hero {
  background-color: #0d3149;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5%;
  position: relative; /* Add position relative for absolute positioning */
}

/* Add styles for content container */
.home-page .content-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 1; /* Ensure content appears above other elements */
}
/* Add styles for hero text */
.home-page .content-container h1 {
  text-align: center;
  color: white; /* Set the text color */
  font-family: 'Your Bubbly Font', sans-serif;
  font-weight: bold;
  font-size: 10vw;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  max-width: 100%;
  position: relative;
  transition: color 0.3s ease; /* Add transition effect for color */
}

/* Hover effect to change text color */
.hero:hover .content-container h1 {
  color: #ff6347; /* Change text color on hover */
}
