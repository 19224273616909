/* Checkout.css */

/* Apply background color to body and ensure html covers the entire viewport */
.checkout-page body, .checkout-page html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #0d3149;
}
/* Styles for the checkout container */
.checkout-container {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap onto the next line */
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  text-align: center;
  min-height: 50vh;
  padding: 10px;
  background: linear-gradient(to bottom right, #cd5b12, #ff6f61); /* Use a gradient background */
  font-family: 'Cutive Mono', monospace;
  border-radius: 10px; /* Add border radius for a softer look */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
}

/* Styles for the cart and checkout sections */
.cart-section, .checkout-section {
  flex: 1; /* Distribute equal space for both sections */
  width: 50%;
  max-width: 600px; /* Limit the maximum width to prevent overflowing */
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8); /* Use a semi-transparent white background */
  font-family: 'Cutive Mono', monospace;
  border-radius: 10px; /* Add border radius for a softer look */
  margin: 10px; /* Add margin between sections */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
}

.cart-section ul {
  list-style-type: none;
  padding: 0;
}

.cart-section li {
  margin-bottom: 10px;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.checkout-section h1 {
  margin-bottom: 20px;
}

.checkout-item-list {
  list-style-type: none;
  padding: 0;
}

.checkout-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.item-details {
  display: flex;
  align-items: center;
}

.quantity {
  display: flex;
  align-items: center;
}

.quantity input {
  margin-left: 10px;
  width: 50px;
}

.total {
  margin-top: 20px;
  font-weight: bold;
}
.BackButton {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  /* Adjust padding to control button size */
  text-align: center;
  background-color: #cd5b12;
  /* Set background color to orange */
  color: white;
  /* Set text color to white */
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  /* Add transition effect */
  text-decoration: none;
}
.BackButton:hover {
  background-color: darkorange;
  /* Change background color on hover */
}