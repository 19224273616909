/* Services Component Styles */
/* src/Services.css */

/* Responsive Design Styles */
/* Default styles for all screen sizes */

/* Small smartphones */
@media (max-width: 320px) {
  /* Component-specific styles for screens up to 320px width */
  .heroABOUT,
  .heroSERVICE,
  .heroTEAM {
    /* Adjust styles for small smartphones */
    min-height: 20vh;
    padding: 2%;
  }
}

/* Larger smartphones */
@media (min-width: 321px) and (max-width: 480px) {
  /* Component-specific styles for screens between 321px and 480px width */
  .heroABOUT,
  .heroSERVICE,
  .heroTEAM {
    /* Adjust styles for larger smartphones */
    min-height: 20vh;
    padding: 2%;
  }
}

/* Tablets */
@media (min-width: 481px) and (max-width: 768px) {
  /* Component-specific styles for screens between 481px and 768px width */
  .heroABOUT,
  .heroSERVICE,
  .heroTEAM {
    /* Adjust styles for tablets */
    min-height: 20vh;
    padding: 2%;
  }
}

/* Small laptops, tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  /* Component-specific styles for screens between 769px and 1024px width */
  .heroABOUT,
  .heroSERVICE,
  .heroTEAM {
    /* Adjust styles for small laptops, tablets */
    min-height: 20vh;
    padding: 2%;
  }
}

/* Larger laptops */
@media (min-width: 1025px) and (max-width: 1200px) {
  /* Component-specific styles for screens between 1025px and 1200px width */
  .heroABOUT,
  .heroSERVICE,
  .heroTEAM {
    /* Adjust styles for larger laptops */
    min-height: 20vh;
    padding: 2%;
  }
}

/* Larger desktops */
@media (min-width: 1201px) and (max-width: 1440px) {
  /* Component-specific styles for screens between 1201px and 1440px width */
  .heroABOUT,
  .heroSERVICE,
  .heroTEAM {
    /* Adjust styles for larger desktops */
    min-height: 20vh;
    padding: 2%;
  }
}

/* Extra-large desktops */
@media (min-width: 1441px) {
  /* Component-specific styles for screens larger than 1440px width */
  .heroABOUT,
  .heroSERVICE,
  .heroTEAM {
    /* Adjust styles for extra-large desktops */
    min-height: 20vh;
    padding: 2%;
  }
}
/* Apply background color to body and ensure html covers the entire viewport */
.about-page body, .about-page html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #0d3149;
}

/* Hero Section Styles for About Page */
#CENTERABOUT {
  padding: 20px;
  box-sizing: border-box;
  min-height: 50vh;
  background-color: #0d3149; /* Adjust background color as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Styles for Content Container in About Page */
.content-containerABOUT {
  text-align: center;
  color: white; /* Adjust text color as needed */
  font-family: 'Cutive Mono', monospace;
}

/* Styles for About Page Heading */
.content-containerABOUT h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  transition: color 0.3s ease; /* Adding transition animation for color change */
}

/* Styles for About Page Paragraph */
.content-containerABOUT p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

/* CENTER ID Styles for Services Page */
#CENTERSERVICE {
  min-height: 100vh;
  background-color: #0d3149;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}

/* Hero Section Styles for Services Page */
.heroSERVICE {
  padding: 20px;
  box-sizing: border-box;
  color: white;
  font-family: 'Cutive Mono', monospace;
  
}

/* Styles for Services Container */
.services-container {
  margin: 20px;
  
}

/* Styles for Services List */
.services-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: white;
  font-family: 'Cutive Mono', monospace;
  
}

/* Styles for Service Item */
.service-item {
  text-align: center;
  margin: 20px; /* Adjust margin to create space between service items */
  flex: 1 0 calc(33.33% - 40px); /* Adjust flex properties to evenly distribute items */
  /* Adjust the width and height to change the size of the logos */
  img {
    width: 100%; /* Set the width to 100% for responsiveness */
    max-width: 200px; /* Set a maximum width if needed */
    height: auto; /* Maintain the aspect ratio */
  }
}

.service-item h2 {
  margin-top: 10px;
  
}

.service-item p {
  margin-top: 10px;
}


.service-item a {
  color: #ffffff; /* Adjust link color as needed */
  text-decoration: none;
  display: block; /* Convert link to block level element */
  margin-top: 20px; /* Add margin to lower the link */
}

.service-item a:hover {
  text-decoration: underline;
}

.heroSERVICE h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #ffffff; /* Adjust text color as needed */
}

/* Team Component Styles */

/* Center ID Styles for Team Page */
#CENTERTEAM {
  min-height: 100vh;
  background-color: #0d3149;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Hero Section Styles for Team Page */
.heroTEAM {
  padding: 20px;
  box-sizing: border-box;
  color: white;
  font-family: 'Cutive Mono', monospace;
}

/* Styles for Team Container */
.team-containerTEAM {
  margin: 20px;
  /* Add hover animation */
  transition: transform 0.3s ease-in-out;
  

  /* Add tilting and floating-secondary animations */
  animation:
    tilting 5s cubic-bezier(0.37, 0, 0.69, 1) infinite,
    floating-secondary 2.5s cubic-bezier(0.57, 1, 0.63, 2) infinite;
}

/* Styles for Team List */
.team-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: white;
  font-family: 'Cutive Mono', monospace;
}

/* Styles for Team Member */
.team-member {
  text-align: center;
  margin: 20px; /* Adjust margin to create space between service items */
  flex: 1 0 calc(33.33% - 40px); /* Adjust flex properties to evenly distribute items */
  /* Adjust the width and height to change the size of the logos */
  img {
    width: 100%; /* Set the width to 100% for responsiveness */
    max-width: 200px; /* Set a maximum width if needed */
    height: auto; /* Maintain the aspect ratio */
    border-radius: 50%; /* Make the image circular (optional) */
  }
}

.team-member h2 {
  margin-top: 10px;
}

.team-member p {
  margin-top: 10px;
}

.team-member a {
  color: #ffffff; /* Adjust link color as needed */
  text-decoration: none;
  display: block; /* Convert link to block level element */
  margin-top: 10px; /* Add margin to lower the link */
}

.team-member a:hover {
  text-decoration: underline;
}

.heroTEAM h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #ffffff; /* Adjust text color as needed */
}

/* Define the tilting animation */
@keyframes tilting {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
/* Define the floating-secondary animation */
@keyframes floating-secondary {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
