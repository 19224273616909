/* src/App.css */

/* Importing a font from Google Fonts */
@import url('https://fonts.googleapis.com/css?family=Cutive+Mono&display=swap');

/* Resetting default margin and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}
/* Global Styles */
body,
html {
  width: 100%;
  height: 100%;
}

/* Center Styles */
.App {
  text-align: center;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color:  #0d3149; /* Set a background color to visualize the content area */
}
/* Responsive Design Styles */
/* Default styles for all screen sizes */


/* Small smartphones */
@media (max-width: 480px) {
  /* Component-specific styles for screens up to 480px width */
  .App {
    /* Adjust styles for small smartphones */
    padding: 10px; /* Add padding to center content */
  }
}

/* Tablets */
@media (min-width: 481px) and (max-width: 1024px) {
  /* Component-specific styles for screens between 481px and 1024px width */
  .App {
    /* Adjust styles for tablets */
    padding: 20px; /* Add more padding for larger screens */
  }
}

/* Larger devices */
@media (min-width: 1025px) {
  /* Component-specific styles for screens larger than 1024px width */
  .App {
    /* Adjust styles for larger devices */
    padding: 30px; /* Add even more padding for extra-large screens */
  }
}

/* Extra-large desktops */
@media (min-width: 1441px) {
 /* Component-specific styles for screens larger than 1024px width */
 .App {
  /* Adjust styles for larger devices */
  padding: 40px; /* Add even more padding for extra-large screens */
}
}
