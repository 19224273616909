/* TeamMember3.css */

/* Hero Section Styles */
.rachel-heppner-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px; /* Adjust as needed */
}

/* Rachel Heppner Image Styles */
.rachel-heppner-image img {
  width: 300px; /* Adjust image width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 50%; /* Rounded image */
  margin-bottom: 20px; /* Space between image and description */
  transition: transform 0.3s ease; /* Add transition effect */
}

.rachel-heppner-image img:hover {
  transform: scale(1.1); /* Scale up on hover */
}

/* Rachel Heppner Description Styles */
.rachel-heppner-description {
  text-align: center;
  color: white;
  font-family: 'Cutive Mono', monospace;
  transition: color 0.3s ease; /* Add transition effect */
}

/* Hover effect for description */
.rachel-heppner-description:hover {
  color: lightblue; /* Change color on hover */
}

/* Additional Images Styles */
.additional-images {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* Adjust as needed */
}

.additional-images img {
  width: 150px; /* Adjust image width */
  height: auto; /* Maintain aspect ratio */
  margin: 0 10px; /* Space between additional images */
  transition: transform 0.3s ease; /* Add transition effect */
}

.additional-images img:hover {
  transform: scale(1.1); /* Scale up on hover */
}

/* Go Back Button Styles */
.goBackButton {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  text-align: center;
  background-color: #cd5b12;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.goBackButton:hover {
  background-color: darkorange;
}

/* Footer Styles */
.footer {
  background-color: transparent;
  padding: 20px 0;
  text-align: center;
}

/* Social Media Styles */
.social-media {
  display: flex;
  justify-content: center;
}

.social-media a {
  margin: 0 10px; /* Adjust spacing between social media icons */
}

.social-media img {
  width: 30px; /* Adjust size of social media icons */
  height: 30px;
}
