.service {
    text-align: center;
    margin-bottom: 20px;
    color: white;
    font-family: 'Cutive Mono', monospace;
}

.service img {
    width: 100px;
    /* Adjust the size of the image */
    height: 100px;
    /* Adjust the size of the image */
}

.service h2 {
    margin-top: 10px;
}

.service p {
    margin-bottom: 10px;
}

.goBackButton {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    /* Adjust padding to control button size */
    text-align: center;
    background-color: #cd5b12;
    /* Set background color to orange */
    color: white;
    /* Set text color to white */
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    /* Add transition effect */
      text-decoration: none;
}

.goBackButton:hover {
    background-color: darkorange;
    /* Change background color on hover */
}

/* Additional animations */
.service:hover {
    transform: translateY(-5px); /* Add translateY animation on hover */
}

.service img:hover {
    transform: scale(1.1); /* Add scaling animation on image hover */
}

.service h2:hover {
    color: orange; /* Change text color on hover */
}

.service p:hover {
    opacity: 0.8; /* Reduce opacity on hover */
}
