/* TeamMember2.css */

/* Adjust as needed */
.mason-bennett-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}

/* Adjust image width and maintain aspect ratio */
.mason-bennett-image img {
  width: auto;
  height: auto;
  border-radius: 50%;
  margin-bottom: 20px;
  /* Add transition effect for image scaling */
  transition: transform 0.3s ease;
}

/* Adjust text styles */
.mason-description {
  text-align: center;
  color: white;
  font-family: 'Cutive Mono', monospace;
}

/* Adjust spacing and add hover effect for additional images */
.additional-images {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.additional-images img {
  width: 150px;
  height: auto;
  margin: 0 10px;
  /* Add transition effect for image scaling */
  transition: transform 0.3s ease;
}

.additional-images img:hover {
  transform: scale(1.1); /* Scale up on hover */
}

/* Button styles with hover effect */
.goBackButton {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  text-align: center;
  background-color: #cd5b12;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Transition for background color */
}

.goBackButton:hover {
  background-color: darkorange;
}

/* Footer styles */
.footer {
  background-color: transparent;
  padding: 20px 0;
  text-align: center;
}

/* Social media icons */
.social-media {
  display: flex;
  justify-content: center;
}

.social-media a {
  margin: 0 10px;
}

.social-media img {
  width: 30px;
  height: 30px;
  /* Add transition effect for social media icons */
  transition: transform 0.3s ease;
}

.social-media img:hover {
  transform: scale(1.2); /* Scale up on hover */
}
