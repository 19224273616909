/* ShoppingCartItem.css */
/* Responsive Design Styles */
/* Default styles for all screen sizes */

/* Small smartphones */
@media (max-width: 320px) {
  /* Component-specific styles for screens up to 320px width */
    .shopping-cart-item 
 {
    min-height: 20vh;
    padding: 2%;
  }
}

/* Larger smartphones */
@media (min-width: 321px) and (max-width: 480px) {
  /* Component-specific styles for screens between 321px and 480px width */
    .shopping-cart-item 
 {
  min-height: 20vh;
  padding: 5%; /* Changed padding from 2% to 5% */
  }
}

/* Tablets */
@media (min-width: 481px) and (max-width: 768px) {
  /* Component-specific styles for screens between 481px and 768px width */
    .shopping-cart-item 
 {
  min-height: 30vh;
  padding: 5%; /* Changed padding from 2% to 5% */
  }
}

/* Small laptops, tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  /* Component-specific styles for screens between 769px and 1024px width */
    .shopping-cart-item 
 {
  min-height: 40vh;
  padding: 5%; /* Changed padding from 2% to 5% */
  }
}

/* Larger laptops */
@media (min-width: 1025px) and (max-width: 1200px) {
  /* Component-specific styles for screens between 1025px and 1200px width */
    .shopping-cart-item 
 {
  min-height: 50vh;
  padding: 5%; /* Changed padding from 2% to 5% */
  }
}

/* Larger desktops */
@media (min-width: 1201px) and (max-width: 1440px) {
  /* Component-specific styles for screens between 1201px and 1440px width */
    .shopping-cart-item 
 {
  min-height: 50vh;
  padding: 5%; /* Changed padding from 2% to 5% */
  }
}

/* Extra-large desktops */
@media (min-width: 1441px) {
  /* Component-specific styles for screens larger than 1440px width */
  
  .shopping-cart-item {
    min-height: 50vh;
    padding: 5%; /* Changed padding from 2% to 5% */
  }
}
/* ShoppingCartItem.css */

/* Component-specific styles */
.shopping-cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  font-family: 'Cutive Mono', monospace;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transitions for smoother animations */
}

.shopping-cart-item:hover {
  transform: translateY(-5px); /* Add translateY transformation on hover for slight elevation effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add box shadow on hover for depth effect */
}

.item-details {
  flex: 1;
}

.item-price {
  margin-right: 10px;
}

.item-name {
  font-weight: bold;
  margin-right: 10px;
}

.item-image {
  width: 100px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px;
}

.remove-button {
  background-color: #ff6347;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.remove-button:hover {
  background-color: #d43f3a;
}

/* Responsive Design Styles */
/* Add your responsive design styles here */
/* You can keep the existing media queries for responsiveness */
