/* src/components/Projects.css */
/* Responsive Design Styles */
/* Default styles for all screen sizes */

/* Small smartphones */
@media (max-width: 320px) {
  /* Component-specific styles for screens up to 320px width */
  .heroPROJECT {
    /* Adjust styles for small smartphones */
    min-height: 20vh;
    padding: 2%;
  }
}

/* Larger smartphones */
@media (min-width: 321px) and (max-width: 480px) {
  /* Component-specific styles for screens between 321px and 480px width */
  .heroPROJECT {
    /* Adjust styles for larger smartphones */
    min-height: 20vh;
    padding: 2%;
  }
}

/* Tablets */
@media (min-width: 481px) and (max-width: 768px) {
  /* Component-specific styles for screens between 481px and 768px width */
  .heroPROJECT {
    /* Adjust styles for tablets */
    min-height: 30vh;
    padding: 3%;
  }
  .square-container {
    width: calc(100% - 20px); /* Full width with margin space */
  }
}

/* Small laptops, tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  /* Component-specific styles for screens between 769px and 1024px width */
  .heroPROJECT {
    /* Adjust styles for small laptops, tablets */
    min-height: 30vh;
    padding: 3%;
  }
}

/* Larger laptops */
@media (min-width: 1025px) and (max-width: 1200px) {
  /* Component-specific styles for screens between 1025px and 1200px width */
  .heroPROJECT {
    /* Adjust styles for larger laptops */
    min-height: 30vh;
    padding: 3%;
  }
}

/* Larger desktops */
@media (min-width: 1201px) and (max-width: 1440px) {
  /* Component-specific styles for screens between 1201px and 1440px width */
  .heroPROJECT {
    /* Adjust styles for larger desktops */
    min-height: 30vh;
    padding: 3%;
  }
}

/* Extra-large desktops */
@media (min-width: 1441px) {
  /* Component-specific styles for screens larger than 1440px width */
  .heroPROJECT {
    /* Adjust styles for extra-large desktops */
    min-height: 30vh;
    padding: 3%;
  }
}

/* Apply background color to body and ensure html covers the entire viewport */
.projects-page body, .projects-page html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #0d3149;
}

/* Hero Section Styles for Projects Page */
#CENTERPROJECT {
  padding: 20px;
  box-sizing: border-box;
  min-height: 50vh;
  background-color: #0d3149; /* Adjust background color as needed */
  display: flex;
  align-items: center;
  justify-content: center;
 /* Other styles */
 transition: background-color 0.3s ease-in-out; /* Transition effect for background color */
}
#CENTERPROJECT:hover {
  background-color: #1a5276; /* Change background color on hover */
}

/* Styles for Content Container in Projects Page */
.content-containerPROJECT {
  text-align: center;
  color: white; /* Adjust text color as needed */
  font-family: 'Cutive Mono', monospace;
}

/* Styles for Projects Page Heading */
.content-containerPROJECT h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #ffc107; /* Change text color on hover */
}

/* Styles for Projects Page Paragraph */
.content-containerPROJECT p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}
/* Styles for Projects Page Paragraph on hover */
.content-containerPROJECT p:hover {
  color: #ffc107; /* Change text color on hover */
}

/* ... Previous styles ... */

.square-container {
  /* Set the size of the square container */
  /* Set the size of the square container and add margin for spacing */
  width: calc(
    50% - 20px
  ); /* 20px is the total margin space (10px for each side) */
  margin: 10px; /* Add margin for spacing between projects */

  width: 200px;
  height: 200px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  background-color: #0d3149; /* Set the background color */

  /* Add hover animation */
  transition: transform 0.3s ease-in-out;

  /* Add tilting and floating-secondary animations */
  animation:
    tilting 5s cubic-bezier(0.37, 0, 0.69, 1) infinite,
    floating-secondary 2.5s cubic-bezier(0.57, 1, 0.63, 2) infinite;

     /* Other styles */
  transition: transform 0.3s ease-in-out; /* Transition effect for scaling */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.square-container:hover {
  transform: scale(1.1); /* Scale up on hover */
  transform: scale(1.05); /* Scale up on hover */
}

.square-container img {
  /* Set the size of the image to cover the square container */
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* Hover effect for image inside square-container */
.square-container:hover img {
  filter: brightness(70%); /* Adjust brightness on hover */
}


.text-overlay {
  /* Position the transparent text in the center of the square */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: transparent;
  transition: color 0.3s ease-in-out;
   /* Other styles */
   transition: color 0.3s ease-in-out; /* Transition effect for text color */
   transition: opacity 0.3s ease-in-out; /* Transition effect for opacity */
}

/* Hover effect for text overlay */
.square-container:hover .text-overlay {
  color: rgba(255, 255, 255, 0.8);
  opacity: 0.8; /* Change opacity on hover */
}
/* Define the floating animation */
@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Apply floating animation to square-container */
.square-container {
  /* Other styles */
  animation: floating 3s ease-in-out infinite; /* Apply floating animation */
}

/* Define the tilting animation */
@keyframes tilting {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Apply tilting animation to square-container */
.square-container:hover {
  animation: tilting 1s ease-in-out infinite; /* Apply tilting animation on hover */
}
