/* Responsive Design Styles */
/* Default styles for all screen sizes */

/* Small smartphones */
@media (max-width: 320px) {
  /* Component-specific styles for screens up to 320px width */
  .heroSHOP {
    min-height: 20vh;
    padding: 2%;
  }
  .content-containerSHOP{
    min-height: 20vh;
    padding: 2%;
  }
  .shopping-items {
    min-height: 20vh;
    padding: 2%;
  }

}

/* Larger smartphones */
@media (min-width: 321px) and (max-width: 480px) {
  /* Component-specific styles for screens between 321px and 480px width */
  .heroSHOP {
    min-height: 20vh;
    padding: 2%;
  }
  .content-containerSHOP{
    min-height: 20vh;
    padding: 2%;
  }
  .shopping-items {
    min-height: 20vh;
    padding: 2%;
  }
}

/* Tablets */
@media (min-width: 481px) and (max-width: 768px) {
  /* Component-specific styles for screens between 481px and 768px width */
  .heroSHOP {
    min-height: 30vh;
    padding: 3%;
  }
  .content-containerSHOP{
    min-height: 30vh;
    padding: 3%;
  }
  .shopping-items {
    min-height: 30vh;
    padding: 3%;
  }
}

/* Small laptops, tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  /* Component-specific styles for screens between 769px and 1024px width */
  .heroSHOP {
    min-height: 30vh;
    padding: 3%;
  }
  .content-containerSHOP{
    min-height: 30vh;
    padding: 3%;
  }
  .shopping-items {
    min-height: 30vh;
    padding: 3%;
  }
}

/* Larger laptops */
@media (min-width: 1025px) and (max-width: 1200px) {
  /* Component-specific styles for screens between 1025px and 1200px width */
  .heroSHOP {
    min-height: 30vh;
    padding: 3%;
  }
  .content-containerSHOP{
    min-height: 30vh;
    padding: 3%;
  }
  .shopping-items {
    min-height: 30vh;
    padding: 3%;
  }
}

/* Larger desktops */
@media (min-width: 1201px) and (max-width: 1440px) {
  /* Component-specific styles for screens between 1201px and 1440px width */
  .heroSHOP {
    min-height: 30vh;
    padding: 3%;
  }
  .content-containerSHOP{
    min-height: 30vh;
    padding: 3%;
  }
  .shopping-items {
    min-height: 30vh;
    padding: 3%;
  }
}

/* Extra-large desktops */
@media (min-width: 1441px) {
  /* Component-specific styles for screens larger than 1440px width */

  .heroSHOP {
    min-height: 30vh;
    padding: 3%;
  }
  .content-containerSHOP{
    min-height: 30vh;
    padding: 3%;
  }
  .shopping-items {
    min-height: 30vh;
    padding: 3%;
  }
}

/* Apply background color to body and ensure html covers the entire viewport */
.shop-page body, .shop-page html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #0d3149;
}
/* CENTER ID Styles for Services Page */
#CENTERSHOP {
  min-height: 100vh;
  background-color: #cd5b12;
  color: white; /* Apply white text color */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Cutive Mono', monospace;
}

/* Hero Section Styles for Services Page */
.heroSHOP {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  animation: fadeInUp 1s ease;
}

/* Apply fade-in animation to hero section */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


/* Styles for Services Container */
.content-containerSHOP {
  margin: 0 auto; /* Center the container horizontally */
  width: 90%; /* Adjust width as needed */
  max-width: 1200px; /* Limit the maximum width to prevent overflowing */
}
/* Styles for Shopping Items */
.shopping-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
  animation: fadeIn 1s ease;
}

/* Apply fade-in animation to shopping items */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Styles for Individual Shopping Item */
.item {
  flex: 1;
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  position: relative;
  margin: 10px;
  box-sizing: border-box;
  animation: scaleIn 0.5s ease forwards;
}

/* Apply scale-in animation to individual items */
@keyframes scaleIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}


.item img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.item h3 {
  font-size: 18px;
  margin-bottom: 5px;
}

.item p {
  margin-bottom: 10px;
}

.item button {
  background-color:#cd5b12;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-family: 'Cutive Mono', monospace;
}

.item button:hover {
  background-color: #0056b3;
}
/* Styles for Add to Cart Button */
.item button.add-to-cart {
  background-color: #cd5b12;
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  font-family: 'Cutive Mono', monospace;
  transition: background-color 0.3s ease;
}

.item button.add-to-cart:hover {
  background-color: #0056b3;
}


/* Styles for Save for Later Button */
.item button.save-for-later {
  background-color: #cd5b12;
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
}

/* Styles for Shopping Cart Dropdown */
.shopping-cart-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 999;
  animation: slideInDown 0.5s ease forwards;
}

/* Apply slide-in animation to shopping cart dropdown */
@keyframes slideInDown {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Styles for Shopping Cart Dropdown Title */
.shopping-cart-dropdown h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

/* Styles for Shopping Cart Dropdown Items */
.shopping-cart-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Styles for Individual Cart Item in Dropdown */
.shopping-cart-dropdown li {
  border: 1px solid #ccc;
  padding: 8px;
  margin-bottom: 5px;
}

/* Styles for Remove Item Button in Dropdown */
.shopping-cart-dropdown li button.remove-from-cart {
  background-color: #e91e63;
  color: white;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

/* Styles for Checkout Button in Dropdown */
.shopping-cart-dropdown .checkout-button {
  background-color: #ff9800;
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

/* Hide the dropdown by default */
.shopping-cart-dropdown {
  display: none;
}

/* Show the dropdown when the button is clicked */
.shopping-cart-dropdown.show {
  display: block;
}



/* Styles for Check Out Button */
.checkout-button {
  background-color: #ff9800;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

/* Styles for Payment Methods */
.payment-methods {
  margin-top: 20px;
}

/* Styles for Individual Payment Method */
.payment-methods label {
  display: block;
  margin-bottom: 10px;
}

/* Styles for Add Payment Method Button */
.add-payment-method {
  background-color: #2196f3;
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
}

/* Styles for Saved for Later */
.saved-for-later {
  margin-top: 20px;
}

/* Styles for Saved for Later Title */
.saved-for-later h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

/* Styles for Saved for Later Items */
.saved-for-later ul {
  list-style: none;
  padding: 0;
}

/* Styles for Individual Saved for Later Item */
.saved-for-later li {
  border: 1px solid #ccc;
  padding: 8px;
  margin-bottom: 5px;
  position: relative;
}

/* Styles for Add Item Back to Cart Button */
.saved-for-later li button.add-back-to-cart {
  background-color: #2196f3;
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
}
