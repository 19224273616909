.shopping-item {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease-in-out; /* Add transition for smooth scaling */
}

.shopping-item:hover {
  transform: scale(1.05); /* Add scale transformation on hover */
}

.item-name {
  font-size: 1.2rem;
  font-weight: bold;
}

.item-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.item-description {
  margin-bottom: 5px;
  text-align: center;
}

.item-description p {
  margin-bottom: 5px;
  text-align: center;
}


.item-image img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.item-actions {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.item-actions input {
  width: 50px;
  margin-right: 10px;
}

.item-actions button {
  margin-right: 10px;
  transition: background-color 0.3s ease; /* Add transition for background color */
}

.item-actions button:hover {
  background-color: #ff7f50; /* Change background color on hover */
}
