.saved-for-later-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  animation: fadeIn 1s ease forwards;
}

/* Apply fade-in animation to saved-for-later items */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.item-details {
  flex: 1;
}

.item-name {
  font-weight: bold;
  margin-right: 10px;
}

.item-actions {
  display: flex;
  align-items: center;
}

.add-to-cart-button {
  background-color: #5cb85c;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-to-cart-button:hover {
  background-color: #4cae4c;
}

.remove-from-saved-button {
  background-color: #5cb85c;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.remove-from-saved-button:hover {
  background-color: #d43f3a;
}
