/* Responsive Design Styles */
/* Default styles for all screen sizes */

/* Small smartphones */
@media (max-width: 320px) {

  /* Component-specific styles for screens up to 320px width */
  /* Adjust styles for screens up to 480px width */
  #CENTERCONTACT {
    min-height: 20vh;

    padding: 5%; /* Changed padding from 2% to 5% */
  }
}

/* Larger smartphones */
@media (min-width: 321px) and (max-width: 480px) {

  /* Component-specific styles for screens between 321px and 480px width */
  /* Adjust styles for screens up to 480px width */
  #CENTERCONTACT {
    min-height: 20vh;
   
    padding: 5%; /* Changed padding from 2% to 5% */
  }
}

/* Tablets */
@media (min-width: 481px) and (max-width: 768px) {
m #CENTERCONTACT {
  min-height: 30vh;
  padding: 5%; /* Changed padding from 2% to 5% */
}
}

/* Small laptops, tablets */
@media (min-width: 769px) and (max-width: 1024px) {

  /* Component-specific styles for screens between 769px and 1024px width */
  /* Adjust styles for screens up to 480px width */
  #CENTERCONTACT {
    min-height: 40vh;
    padding: 5%; /* Changed padding from 2% to 5% */
  }
}

/* Larger laptops */
@media (min-width: 1025px) and (max-width: 1200px) {

  /* Component-specific styles for screens between 1025px and 1200px width */
  /* Adjust styles for screens up to 480px width */
  #CENTERCONTACT {
    min-height: 50vh;
    padding: 5%; /* Changed padding from 2% to 5% */
  }
}

/* Larger desktops */
@media (min-width: 1201px) and (max-width: 1440px) {

  /* Component-specific styles for screens between 1201px and 1440px width */
  /* Adjust styles for screens up to 480px width */
  #CENTERCONTACT {
    min-height: 50vh;
    padding: 5%; /* Changed padding from 2% to 5% */
  }
}

/* Extra-large desktops */
@media (min-width: 1441px) {

  /* Component-specific styles for screens larger than 1440px width */
  /* Adjust styles for screens up to 480px width */
  #CENTERCONTACT {
    min-height: 50vh;
    padding: 5%; /* Changed padding from 2% to 5% */
  }
}

/* Apply background color to body and ensure html covers the entire viewport */
.contact-page body, .contact-page html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #0d3149;
  overflow: hidden; /* Eliminate scrolling */
}

/* Styles for the contact form container */
.CENTERCONTACT {
  padding: 5%;
  box-sizing: border-box;
  min-height: 50vh;
  background-color: #cd5b12;
  text-align: center; /* Align content center horizontally */
  display: flex;
  flex-direction: column; /* Align content center vertically */
  align-items: center;
  justify-content: center;
  position: relative;
  color: white;
  font-family: 'Cutive Mono', monospace;
  overflow: hidden; /* Eliminate scrolling */
}

/* Styles for the content container in contact form */
#content-containerCONTACT {
  text-align: center;
  color: white;
  font-family: 'Cutive Mono', monospace;
  position: relative;
  /* Add position relative */
  z-index: 1;
  /* Add a z-index value */
  width: 80%; /* Adjust width as needed */
  margin: 0 auto; /* Center content horizontally */
}

/* Styles for contact form heading */
#content-containerCONTACT h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

/* Styles for contact form paragraph */
#content-containerCONTACT p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}


/* Styles for form elements */
.form-group {
  margin-bottom: 20px;
  position: relative;
  /* Add position relative */
  z-index: 2;
  /* Add a z-index value higher than the content container */
}

label {
  display: block;
  font-weight: bold;
}

input[type='text'],
input[type='email'],
textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

textarea {
  resize: vertical;
}

button[type='submit'] {
  background-color: #0d3149;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Cutive Mono', monospace;
}

button[type='submit']:hover {
  background-color: #cd5b12;
  font-family: 'Cutive Mono', monospace;
}

/* Error message styles */
.error-message {
  color: red;
  margin-top: 5px;
  font-size: 14px;
  font-family: 'Cutive Mono', monospace;
}

/* Styles for notification message */
.notification {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  border-radius: 5px;
  z-index: 9999;
  font-family: 'Cutive Mono', monospace;
}

/* Styles for the button to send another message */
.send-another-button {
  display: block;
  margin-top: 20px;
  background-color: #cd5b12;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Cutive Mono', monospace;
}

.send-another-button:hover {
  background-color: #cd5b12;
  font-family: 'Cutive Mono', monospace;
}