/* Footer Component Styles */
/* src/components/Footer.js */

/* Media Query for smaller screens (adjust as needed) */
@media (max-width: 768px) {
  .instagram-icon,
  .facebook-icon,
  .twitter-icon,
  .tiktok-icon {
    width: 30px; /* Adjust the width for smaller screens */
    margin-right: 10px; /* Adjust the margin for smaller screens */
  }
}

/* FOOT ID Styles */
#FOOT {
  padding: 1vh;
  width: 100%;
  box-sizing: border-box;
  background-color: #0d3149;
  justify-content: space-around; /* Evenly distribute items horizontally */
  align-items: center;
}

/* Styles for Footer Content */
.Footer-content {
  font-family: 'Cutive Mono', monospace;
  color: white;
  text-align: center; /* Center-align text */
}

.logo-icon {
  background-color: #0d3149;
  background-image: url('../assets/logos/O.Logo3.png');
}

.contact-info {
  width: 10vw;
  height: 8vw;
  justify-content: center; /* Center the social icons horizontally */
  align-items: center;
  margin-left: 40vw;
  margin-top: -3vw;
}
/* Styles for Text in Footer */
.social-icons {
  display: flex;
  display: inline-block;
  justify-content: center; /* Center the social icons horizontally */
  align-items: center;
}

/* Social Media Icons Styles */
.instagram-icon {
  width: 5vw;
  height: 6vw;
  margin-left: -7vw;
  margin-top: -3vw;
  display: flex;
  display: inline-block;
  justify-content: center; /* Center the social icons horizontally */
}
.twitter-icon {
  width: 4vw;
  height: 4;
  margin-left: 0vw;
  margin-top: -4vw;
}
.tiktok-icon {
  width: 4vw;
  height: 4;
  margin-left: 3vw;
  margin-top: -5vw;
}
.twitter-icon img,
.tiktok-icon img,
.instagram-icon img {
  width: 100%;
  height: auto;
}

.contact-info:hover {
  transform: scale(1.4); /* Increase the size on hover */
  transition: transform 0.5s ease; /* Add a smooth transition effect */
}

/* Social Media Icons Hover Effect */
.instagram-icon:hover {
  transform: scale(1.2); /* Increase the size on hover */
  transition: transform 0.3s ease; /* Add a smooth transition effect */
}
