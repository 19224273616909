/* Header Component Styles */
/* src/components/Header.js */

/* Responsive Design Styles */
/* Default styles for all screen sizes */

/* Small smartphones */
@media (max-width: 320px) {

  /* Component-specific styles for screens up to 320px width */
  #Top {
    height: 60vh;
    /* Adjust the height as needed */
    min-height: 20vh;
    padding: 2%;
  }

  /* Additional components for small screens */
  #nav-menu-icon-right {
    display: none;
  }

  /* Hide navigation bar for larger screens */
  .nav-grid {
    display: none;
  }

  /* Show hamburger menu for smaller screens */
  #bar-menu-container {
    display: block;
    /* Display the menu container on small screens */
  }

  /* Hide the dropdown menu by default on small screens */
  .dropdown-menu {
    display: none;
  }
  

  /* Show nav-message for screens larger than 480px and on the homepage */
  .nav-message {
    display: none;
  }
}

/* Larger smartphones */
@media (min-width: 321px) and (max-width: 480px) {

  /* Component-specific styles for screens up to 320px width */
  #Top {
    height: 60vh;
    /* Adjust the height as needed */
    min-height: 20vh;
    padding: 2%;
  }

  /* Additional components for small screens */
  #nav-menu-icon-right {
    display: none;
  }

  /* Hide navigation bar for larger screens */
  .nav-grid {
    display: none;
  }

  /* Show hamburger menu for smaller screens */
  #bar-menu-container {
    display: block;
    /* Display the menu container on small screens */
  }

  /* Hide the dropdown menu by default on small screens */
  .dropdown-menu {
    display: none;
  }

  /* Show nav-message for screens larger than 480px and on the homepage */
  .nav-message {
    display: none;
  }
}

/* Tablets */
@media (min-width: 481px) and (max-width: 768px) {

  /* Component-specific styles for screens up to 320px width */
  #Top {
    height: 60vh;
    /* Adjust the height as needed */
    min-height: 20vh;
    padding: 2%;
  }

  /* Additional components for small screens */
  #nav-menu-icon-right {
    position: relative;
    top: 0;
    right: 0;
    max-width: none;
    margin-top: 0;
  }

  /* Hide navigation bar for larger screens */
  .nav-grid {
    display: flex;
  }

  /* Show hamburger menu for smaller screens */
  #bar-menu-container {
    display: none;
  }

  /* Add a class for visibility on larger screens and homepage */
  .nav-message {
    display: block;
  }
}

/* Small laptops, tablets */
@media (min-width: 769px) and (max-width: 1024px) {

  /* Component-specific styles for screens up to 320px width */
  #Top {
    height: 60vh;
    /* Adjust the height as needed */
    min-height: 20vh;
    padding: 2%;
  }

  /* Additional components for small screens */
  #nav-menu-icon-right {
    position: relative;
    top: 0;
    right: 0;
    max-width: none;
    margin-top: 0;
  }

  /* Hide navigation bar for larger screens */
  .nav-grid {
    display: flex;
  }

  /* Show hamburger menu for smaller screens */
  #bar-menu-container {
    display: none;
  }

  /* Add a class for visibility on larger screens and homepage */
  .nav-message {
    display: block;
  }
}

/* Larger laptops */
@media (min-width: 1025px) and (max-width: 1200px) {

  /* Component-specific styles for screens up to 320px width */
  #Top {
    height: 60vh;
    /* Adjust the height as needed */
    min-height: 20vh;
    padding: 2%;
  }

  /* Additional components for small screens */
  #nav-menu-icon-right {
    position: relative;
    top: 0;
    right: 0;
    max-width: none;
    margin-top: 0;
  }

  /* Hide navigation bar for larger screens */
  .nav-grid {
    display: flex;
  }

  /* Show hamburger menu for smaller screens */
  #bar-menu-container {
    display: none;
  }

  /* Add a class for visibility on larger screens and homepage */
  .nav-message {
    display: block;
  }
}

/* Larger desktops */
@media (min-width: 1201px) and (max-width: 1440px) {

  /* Component-specific styles for screens up to 320px width */
  #Top {
    height: 60vh;
    /* Adjust the height as needed */
    min-height: 20vh;
    padding: 2%;
  }

  /* Additional components for small screens */
  #nav-menu-icon-right {
    position: relative;
    top: 0;
    right: 0;
    max-width: none;
    margin-top: 0;
  }

  /* Hide navigation bar for larger screens */
  .nav-grid {
    display: flex;
  }

  /* Show hamburger menu for smaller screens */
  #bar-menu-container {
    display: none;
  }

  /* Add a class for visibility on larger screens and homepage */
  .nav-message {
    display: block;
  }
}

/* Extra-large desktops */
@media (min-width: 1441px) {

  /* Component-specific styles for screens up to 320px width */
  #Top {
    height: 60vh;
    /* Adjust the height as needed */
    min-height: 20vh;
    padding: 2%;
  }

  /* Additional components for small screens */
  #nav-menu-icon-right {
    position: relative;
    top: 0;
    right: 0;
    max-width: none;
    margin-top: 0;
  }

  /* Hide navigation bar for larger screens */
  .nav-grid {
    display: flex;
  }

  /* Show hamburger menu for smaller screens */
  #bar-menu-container {
    display: none;
  }

  /* Add a class for visibility on larger screens and homepage */
  .nav-message {
    display: block;
  }
}

/* universal code used in both home page and non homepage  */
/* Universal code used in both home page and non-homepage */

/* Add a transition for smoother opacity changes */


/* Styling for the top section */
#Top {
  position: relative;
  /* Add this line */
  height: 60vh;
  /* Adjust the height as needed */
  /* Adjust the max-width as needed */
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  display: block;
  background-color: #0d3149;
  /* Adjust the height as needed */
}

/* Show hamburger menu for smaller screens */
#bar-menu-container {
  display: block;
  font-size: 24px;
  font-family: 'Cutive Mono', monospace;
  /* Adjust the font size as needed */
}

/* Dropdown menu styles */
.dropdown-menu {
  display: none;
  /* Hide the dropdown menu by default */
  
}
/* Show hamburger menu for smaller screens */
.dropdown-menu .show {
  display: block;
  /* Hide the dropdown menu by default */
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black background */
  z-index: 2;
  /* Ensure the dropdown is on top of other content */
  flex-direction: column;
  /* Adjust the layout as needed */
  justify-content: center;
  align-items: center;
  font-family: 'Cutive Mono', monospace;
}
.dropdown-menu .show #dark-overlay {
  display: block;
}

.dropdown-menu a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-menu a:hover {
  background-color: #ddd;
  /* Adjust hover background color as needed */
  color: black;
  /* Change the text color when hovered */
}

/* Styles for the darkened background when dropdown is visible */
#dark-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black background */
  z-index: 1;
  /* Ensure the overlay is behind the dropdown */
}

/* Show dropdown menu when bar-menu-container is clicked */
#bar-menu-container:hover .dropdown-menu {
  display: block;
}

/* Nav Menu Icon Styles */
#nav-menu-icon-right {
  position: fixed;
  /* Change to fixed */
  top: 20px;
  right: 30px;
  max-width: 60px;
  box-sizing: border-box;
  display: block;
  font-size: 14px;
  line-height: 20px;
  background-image: url('../assets/logos/O.Logo3.png');
  background-size: contain;
  /* Change from 'cover' to 'contain' */
  background-repeat: no-repeat;
  /* Ensure the image is not repeated */
  width: 8vw;
  height: 8vw;
  cursor: pointer;
}

/* On homepage sticky menu */
.sticky-menu-main {
  font-weight: 400;
  position: fixed;
  /* Change to fixed */

  z-index: 9999;
  padding: 3%;
  top: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* Adjust to 100% width */
  font-size: 14px;
  line-height: 20px;
  height: 50vh;
  font-family: 'Cutive Mono', monospace;
}

/* Navigation grid styling */
.nav-grid {
  justify-content: space-between;
  align-items: start;
  margin-left: 0;
  box-sizing: border-box;
  font-weight: 400;
  cursor: default;
  font-size: 14px;
  height: 50vh;
}

/* Navigation message styling */
.nav-message {
  width: 250px;
  overflow: hidden;
  border: none;
  padding: 10px;
  outline: none !important;
  box-shadow: none !important;
  position: absolute;
  top: 20px;
  left: 20px;
}

.nav-message p {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  white-space: nowrap;
  margin: 0;
  font-family: 'Cutive Mono', monospace;
  font-size: 14px;
  line-height: 20px;
  color: white;
  font-weight: 400;
  text-align: left;
}

/* Remove bullet points from the nav-message */
.nav-message ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-message li {
  margin-bottom: 10px;
  /* Adjust as needed */
}

/* Navigation bar styling */
.navigation-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15rem;
  position: fixed;
  right: 100px;
  font-size: 10px;
  line-height: 20px;
  top: 0;
  left: 0;
  right: 0;
}

/* Text Styles */

/* Main menu styling */
.menu-main {
  display: flex;
  align-items: center;
}

/* Styles for main navigation text full group items */
.main-menu-container {
    text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
  position: fixed;
  top: 30px;
  right: 30;
  /* Adjust the right distance as needed */
  left: 30;
  /* Adjust the left distance as needed */
  gap: 20px;
}


/* Styles for main navigation text individual items */
.main-nav-text {
  text-decoration: none;
  color: white;
  padding: 0.5rem 1rem;
  font-family: 'Cutive Mono', monospace;
  transition: transform 0.3s ease;
  font-size: 12px;
  text-align: center;
  margin-right: 10px;
  display: inline-block; /* Ensure consistent alignment */
  transform: scale(1);
  
}
.main-nav-text a{
  text-decoration: none;
  color: white;
  padding: 0.5rem 1rem;
  font-family: 'Cutive Mono', monospace;
  transition: transform 0.3s ease;
  font-size: 12px;
  text-align: center;
  margin-right: 10px;
  display: inline-block; /* Ensure consistent alignment */
  vertical-align: middle; /* Align vertically with other items */
}

.main-nav-text:hover {
  transform: scale(1.1);
}
/* Add animation on hover */
.main-nav-text a::after {
  content: ''; /* Add content for the pseudo-element */
  display: block; /* Ensure the pseudo-element is displayed */
  width: 100%; /* Set the width to cover the entire word */
  height: 2px; /* Set the height of the underline */
  background-color: white; /* Set the color of the underline */
  transform: scaleX(0); /* Initially, make the underline invisible */
  transition: transform 0.3s ease; /* Add transition for smooth animation */
}

.main-nav-text a:hover::after {
  transform: scaleX(1); /* Scale the underline to full width on hover */
}


/* Add spacing between words */
.main-nav-text::after {
  content: ' '; /* Add a space after each navigation item */
}

/* Add styles for main navigation text */
.sub-nav-text {
  position: relative; /* Set position to relative */
  display: inline-block; /* Ensure consistent alignment */
  vertical-align: middle; /* Align vertically with other items */
}

.sub-nav-text ul {
  text-decoration: none;
  color: white;
  padding: 0.5rem 1rem;
  font-family: 'Cutive Mono', monospace;
  transition: transform 0.3s ease;
  font-size: 12px;
  text-align: center;
  margin-right: 10px;
  display: flex; /* Show sub-menu on hover */
  flex-direction: column; /* Arrange sub-menu items vertically */
  padding: 5px 0;
}

.sub-nav-text ul:hover {
  transform: scale(1.1);
}

/* Remove underline and blue color from links */
.sub-nav-text ul a {
  text-decoration: none;
  color: white;
  padding: 0.5rem 1rem;
  font-family: 'Cutive Mono', monospace;
  transition: transform 0.3s ease;
  font-size: 12px;
  text-align: center;
  margin-right: 10px;
  display: flex; /* Show sub-menu on hover */
  flex-direction: column; /* Arrange sub-menu items vertically */
  padding: 5px 0;
}


.sub-nav-text ul a:hover {
  transform: scale(1.1);
}

/* not on homepage */

/* Nav Menu Icon Styles */
.nav-menu-icon-left {
  background-image: url('../assets/logos/O.Logo4.png');
  background-size: cover;
  box-sizing: border-box;
  width: 20vw;
  height: 20vw;
  cursor: pointer;
  position: fixed;
  /* Change to fixed */
  top: 20px;
  left: 20px;
  font-size: 14px;
  line-height: 20px;
}

/* Hover effect for Nav Menu Icon */
.nav-menu-icon-left:hover,
.nav-menu-icon-right:hover {
  transform: scale(1.1); /* Scale up on hover */
}